import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatCheckboxChange} from "@angular/material/checkbox";
import {IRole} from "../../models/user";

@Component({
  selector: 'ngx-l2-role-select',
  templateUrl: './role-select.component.html',
  styleUrls: ['./role-select.component.scss']
})
export class RoleSelectComponent {
  @Input() possibleRoles: IRole[] = [];
  @Input() currentRoleIds: string[] = [];
  @Output() currentRoleIdsChange = new EventEmitter<string[]>();


  updateRolesIds(event: MatCheckboxChange, roleId: string) {
    if (roleId) {
      if (event?.checked) {
        this.onAddRole(roleId);
      } else if (!event?.checked) {
        this.onRemoveRole(roleId)
      }
    }
  }

  onAddRole(roleId: string) {
    if (!this.currentRoleIds) {
      this.currentRoleIds = [];
    }
    this.currentRoleIds.push(roleId);
    this.currentRoleIdsChange.emit(this.currentRoleIds);
  }

  onRemoveRole(rolesId: string) {
    if (!this.currentRoleIds) {
      this.currentRoleIds = [];
    }
    const i = this.currentRoleIds.findIndex(grId => grId === rolesId);
    if (i >= 0) {
      this.currentRoleIds.splice(i, 1);
      this.currentRoleIdsChange.emit(this.currentRoleIds);

    }
  }

  hasRole(roleId: string): boolean {
    return this.currentRoleIds?.indexOf(roleId) > -1;
  }
}
