import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OauthComponent } from './oauth/oauth.component';
import { GlobalLoadingComponent } from './loading/global-loading/global-loading.component';
import { NgxHekaDesignLibModule } from 'ngx-heka-design-lib';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { RoleSelectComponent } from './role-select/role-select.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [OauthComponent, GlobalLoadingComponent, RoleSelectComponent],
  imports: [CommonModule, NgxHekaDesignLibModule, TranslateModule, MatButton, MatProgressSpinner, MatCheckboxModule],
  exports: [RoleSelectComponent]
})
export class ComponentsModule {}
