export enum ERROR_CODES {
  COMPANYDATA_NOT_FOUND = 0,
  ORDER_NOT_FOUND = 1003,
  PI_NUMBER_NOT_FOUND = 1005,
  OMS_KEY_ENTRY_NOT_FOUND = 1006,
  ADMIN_USERS_INV_USER_ALREADY_EXIST_IN_SYSTEM = 19078,
  ADMIN_USERS_INV_INVITATION_PENDING = 19079,
  ADMIN_USERS_INV_ROLE_NOT_FOUND = 19080
}

export enum AuthErrorCodes {
  NO_COMPANY_DATA = 999,
  DOMAIN_USAGE = 998,
  USER_NOT_FOUND = 997,
  NO_APP_PERMISSION = 0
}
