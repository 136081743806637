import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/local/environment';
import { L1L3AuthService } from 'ngx-auth-util-lib';
import { Router } from '@angular/router';
import { ERROR_CODES } from '../../models/codes';
// import { CompanyDataMgmtService } from '../companydatamgmt.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(
    private translateService: TranslateService,
    private authService: L1L3AuthService,
    private router: Router
  ) {}

  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return this.translateService.instant('ERRORS.NO_INTERNET');
    }
    if (environment.production) {
      return this.translateService.instant('ERRORS.UNEXPECTED_CLIENT_ERROR');
    }
    return error.message ? error.message : error.toString();
  }

  getClientStack(error: Error): string | undefined {
    return error.stack;
  }

  getServerMessage(error: HttpErrorResponse): string | null {
    return this.generateHttpResponseErrorMessage(error);
  }

  getServerStack(error: HttpErrorResponse): string {
    // handle stack trace
    return 'stack';
  }

  generateHttpResponseErrorMessage(err: HttpErrorResponse): string | null {
    let errorMessage: string | null = '';
    if (err.status) {
      switch (err.status) {
        case 400:
          if (err.error?.code) {
            errorMessage = this.handle400Errors(err);
          }
          break;
        case 401:
          errorMessage = this.translateService.instant('ERRORS.NO_PERMISSION');
          break;
        case 403:
          errorMessage = this.translateService.instant('ERRORS.NO_PERMISSION');
          break;
        case 500:
          errorMessage = this.translateService.instant('ERRORS.INTERNAL_SERVER_ERROR');
          break;
        case 503:
          errorMessage = this.translateService.instant('ERRORS.PERSISTENCE_NOT_AVAILABLE');
          break;
      }
    }
    if (!errorMessage) {
      errorMessage = this.translateService.instant('ERRORS.UNEXPECTED_ERROR');
    }
    return errorMessage;
  }

  createUnknownCodeMessage(error: HttpErrorResponse): string {
    let message = this.translateService.instant('ERRORS.UNEXPECTED_ERROR');
    let code = '';
    if (error.status) {
      code = 'Code: ';
      if (error.status === 400 && error.error?.code) {
        code = code + error.error.code;
      } else {
        code = code + error.status;
      }
      message = message + ' ' + code;
    }
    return message;
  }

  private isSecuredHost(url: string): boolean {
    return this.authService.securedHosts.some(n => url.includes(n));
  }

  //TODO? adapt
  private handle400Errors(err: HttpErrorResponse): string {
    let errorMessage = '';
    switch (err.error.code) {
      case ERROR_CODES.ORDER_NOT_FOUND:
        errorMessage = this.translateService.instant('ERRORS.ORDER_NOT_FOUND');
        break;
      case ERROR_CODES.OMS_KEY_ENTRY_NOT_FOUND:
        errorMessage = this.translateService.instant('ERRORS.OMS_KEY_ENTRY_NOT_FOUND');
        break;
      // case ERROR_CODES.DOMAIN_COMPANY_DATA_ALREADY_EXISTS:
      //   errorMessage = this.translateService.instant('ERRORS.DOMAIN_COMPANY_DATA_ALREADY_EXISTS');
      //   break;
      // case ERROR_CODES.DOMAIN_COMPANY_DATA_NOT_FOUND:
      //   errorMessage = this.translateService.instant('ERRORS.DOMAIN_COMPANY_DATA_NOT_FOUND');
      //   break;
      // case ERROR_CODES.DOMAIN_COMPANY_LOGO_FILE_NOT_FOUND:
      //   errorMessage = this.translateService.instant('ERRORS.DOMAIN_COMPANY_LOGO_FILE_NOT_FOUND');
      //   break;
      // case ERROR_CODES.DOMAIN_COMPANY_LOGO_FILE_SIZE:
      //   errorMessage = this.translateService.instant('ERRORS.DOMAIN_COMPANY_LOGO_FILE_SIZE');
      //   break;
      // case ERROR_CODES.DOMAIN_COMPANY_LOGO_FORMAT_NOT_SUPPORTED:
      //   errorMessage = this.translateService.instant('ERRORS.DOMAIN_COMPANY_LOGO_FORMAT_NOT_SUPPORTED');
      //   break;
      // case ERROR_CODES.DOMAIN_COMPANY_FILE_DESCR_NOT_FOUND:
      // case ERROR_CODES.DOMAIN_COMPANY_LOGO_FILE_ALREADY_ATTACHED:
      // case ERROR_CODES.DOMAIN_COMPANY_USER_NOT_FOUND:
      // case ERROR_CODES.DOMAIN_COMPANY_USER_CONTRACT_NOT_ACCEPTED:
      // case ERROR_CODES.DOMAIN_COMPANY_DPO_NOT_PROVIDED:
      //   errorMessage = this.translateService.instant('ERRORS.DOMAIN_COMPANY_DATA_GENERAL_ERROR');
      //   break;
      // case ERROR_CODES.USERS_LAST_ADMIN:
      //   errorMessage = this.translateService.instant('ERRORS.USERS_LAST_ADMIN');
      //   break;
      // case ERROR_CODES.USERS_USER_NOT_FOUND:
      //   errorMessage = this.translateService.instant('ERRORS.USERS_USER_NOT_FOUND');
      //   break;
      // case ERROR_CODES.USERS_INVITATION_NOT_FOUND:
      //   errorMessage = this.translateService.instant('ERRORS.USERS_INVITATION_NOT_FOUND');
      //   break;
      // case ERROR_CODES.USERS_ROLE_NOT_FOUND:
      //   errorMessage = this.translateService.instant('ERRORS.USERS_ROLE_NOT_FOUND');
      //   break;
      // case ERROR_CODES.USERS_APP_NOT_FOUND:
      //   errorMessage = this.translateService.instant('ERRORS.USERS_APP_NOT_FOUND');
      //   break;
      // case ERROR_CODES.USERS_INVITATION_PENDING:
      //   errorMessage = this.translateService.instant('ERRORS.USERS_INVITATION_PENDING');
      //   break;
      // case ERROR_CODES.USERS_ALREADY_EXIST:
      //   errorMessage = this.translateService.instant('ERRORS.USERS_ALREADY_EXIST');
      //   break;
      // case ERROR_CODES.USERS_NO_PROTECTED_APP_ACCESS:
      //   errorMessage = this.translateService.instant('ERRORS.USERS_NO_PROTECTED_APP_ACCESS');
      //   break;
      // case ERROR_CODES.USERS_CSV_CREATION_FAILED:
      //   errorMessage = this.translateService.instant('ERRORS.USERS_CSV_CREATION_FAILED');
      //   break;
      // case ERROR_CODES.GROUPS_USER_NOT_FOUND:
      //   errorMessage = this.translateService.instant('ERRORS.GROUPS_USER_NOT_FOUND');
      //   break;
      // case ERROR_CODES.GROUPS_APP_NOT_FOUND:
      //   errorMessage = this.translateService.instant('ERRORS.GROUPS_APP_NOT_FOUND');
      //   break;
      // case ERROR_CODES.GROUPS_NO_PROTECTED_APP_ACCESS:
      //   errorMessage = this.translateService.instant('ERRORS.GROUPS_NO_PROTECTED_APP_ACCESS');
      //   break;
      // case ERROR_CODES.GROUPS_GROUP_NOT_FOUND:
      //   errorMessage = this.translateService.instant('ERRORS.GROUPS_GROUP_NOT_FOUND');
      //   break;
      // case ERROR_CODES.GROUPS_USER_NOT_ASSIGNED_TO_GROUP:
      //   errorMessage = this.translateService.instant('ERRORS.GROUPS_USER_NOT_ASSIGNED_TO_GROUP');
      //   break;
      default:
        errorMessage = this.createUnknownCodeMessage(err);
        break;
    }
    return errorMessage;
  }
}
